
import { Options, Vue } from 'vue-class-component';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import { Ticket } from '@/common/types/Ticket.type';
import { apiService } from '@/services/api.service';
import { Status } from '@/common/enums/status.enum';
import { Time } from '@/common/models/Time.model';
import { format, sub } from 'date-fns';
import { Watch } from 'vue-property-decorator';

@Options({
	name: 'OrderLanding',
	components: {},
})
export default class OrderLanding extends Vue {
	cancelled = false;
	success = false;
	mounted(): void {
		console.log('** ORDER MOUNTED **');
		this.cancelled = !!this.$route.query.cancellation;
		if (this.cancelled) this.$router.replace({ query: {} });
		this.success = !!this.$route.query.success;
		if (this.success) this.$router.replace({ query: {} });
	}
	get ticketUrl(): string | null {
		if (this.order?.ticketUUID) {
			const kioskUrl = (window as any).config.KioskUrl ?? window.location.origin;

			const qrCodeSize = 240;
			var payload = `w;${this.order.ticketUUID}`;

			return `${kioskUrl}/api/qr?s=${qrCodeSize}&p=${payload}`;
		}
		return null;
	}
	get balanceClass() {
		return this.balance < 0 ? 'green' : 'red';
	}
	get type() {
		return this.balance < 0 ? 'credit' : 'due';
	}
	get activityStatuses(): Map<string, (Time & { arrivalTime: number | null })[]> {
		var results: Map<string, (Time & { arrivalTime: number | null })[]> = new Map();

		let allTimes = (this.order?.activities
			.map((a, i) => {
				return a.SelectedTimes.map((time) => {
					return {
						...time,
						arrivalTime: i == 0 && a.ArriveMinutesBefore ? format(sub(new Date(time.Appointment.Time), { minutes: a.ArriveMinutesBefore }), 'h:mm a') : null,
					};
				});
			})
			?.flat() ?? []) as (Time & { arrivalTime: number | null })[];

		allTimes.forEach((time) => {
			var status: string = this.stringForStatus(time.OrderStatusKey);
			var times: (Time & { arrivalTime: number | null })[] = results.get(status) ?? [];
			times.push(time);
			results.set(status, times);
		});

		return results;
	}
	get balance(): number {
		return orderModule._activeOrder?.balance ?? 0;
	}
	linkTo(time: Time): void {
		if (time.OrderEntityTimeKey && time.OrderStatusKey == Status.Upcoming) this.$router.push({ name: 'OrderTime', params: { orderEntityTimeKey: time.OrderEntityTimeKey } });
		else {
			console.error('No OrderEntityTimeKey');
		}
	}
	stringForStatus(status: Status): string {
		switch (status) {
			case Status.Upcoming:
				return 'Upcoming';
			case Status.Cancelled:
				return 'Cancelled';
			case Status['Checked In']:
				return 'Checked In';
			case Status.Complete:
				return 'Complete';
			case Status['No Show']:
				return 'No Show';
			default:
				return 'None';
		}
	}
	get order(): ActiveOrder | null {
		return orderModule._activeOrder;
	}
	get customerName(): string | undefined {
		if (this.order?.customerInfo.FirstName && this.order?.customerInfo.LastName) {
			return this.order?.customerInfo.FirstName + ' ' + this.order?.customerInfo.LastName;
		} else {
			return this.order?.customerInfo.LastName ?? this.order?.customerInfo.FirstName ?? 'No Customer Info';
		}
	}

	get transportationMapping() {
		return this.order?.transportation.map((t) => {
			return {
				date: format(new Date(t.Day), 'EEEE, MMMM dd'),
				guests: `${t.Participants} guest${t.Participants > 1 ? 's' : ''}`,
				pickup: {
					time: t.PickupTimes.at(0)!.TimeString,
					place: t.LocationName,
				},
				return: {
					time: t.ReturnTimes.at(0)!.TimeString,
					place: t.LocationName,
				},
				instructions: t.Instructions,
			};
		});
	}
}
